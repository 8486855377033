import React from "react";
import { Link } from "gatsby";
import Slider from "react-slick";
import styled from "styled-components";
import FlatButton from "../Reusables/FlatButton";
import carouselArrowRight from "../../images/carousel-arrow.svg";
import carouselArrowLeft from "../../images/carousel-left-arrow.svg";

const StyledWrapper = styled.div`
  // Carousel Css

  .slider-slick {
    max-width: ${props => props.theme["percent-90"]};
    margin: auto;
    @media (max-width: 600px) {
      max-width: 100%;
    }
  }

  .slick-arrow {
    width: 72px;
    height: 72px;
    display: none !important;
  }

  .slick-next:before,
  .slick-prev:before {
    color: ${props => props.theme["borderBottomColor"]};
    font-size: ${props => props.theme["pixel-30"]};
    font-weight: bolder;
    line-height: 0.5;
  }

  .slick-prev {
    left: -6.7%;
    z-index: 1;
    background-color: white !important;
  }

  .slick-next {
    right: -6.7%;
    background-color: white;
  }

  .slick-next:hover,
  .slick-prev:hover {
    background-color: white;
  }

  .testimonial-onhover-arrows:hover .slick-arrow {
    display: block !important;
  }

  .slick-prev:before {
    content: url(${carouselArrowLeft});
    transform: rotate(180deg);
  }

  .slick-next:before {
    content: url(${carouselArrowRight});
  }

  .background-color {
    background-color: #304563;
    padding-top: 5%;
    padding-bottom: 5%;
  }

  .testimonial {
    color: #ffffff;
    font-size: 24px;
    font-weight: lighter;
    letter-spacing: 0;
    line-height: 42px;

    @media (max-width: 600px) {
      font-size: 14px;
      line-height: normal;
    }
  }

  .author {
    color: #ffffff;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 16px;
    @media (max-width: 600px) {
      font-size: 15px;
      line-height: normal;
    }
  }

  .testimonial-image {
    flex-shrink: 0;
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
  }
`;

const Testimonialtech500 = ({ carousel, testimonialHeading }) => {
  var settings = {
    speed: 500,
    slidesToShow: 1,
    infinity: true,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          dots: true,
          arrows: false,
        },
      },
    ],
  };
  return (
    <StyledWrapper className="margin-top-100">
      <div className="container-fluid testimonial-onhover-arrows">
        <h1 className="padding-left-8 mb-4">
          {testimonialHeading && testimonialHeading.title}
        </h1>
        <Slider {...settings} className="slider-slick">
          {carousel.length > 0 &&
            React.Children.toArray(
              carousel.map(item => (
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-12 col-md-12 background-color padding-left-8 padding-right-8 boxHeight">
                      <p className="testimonial"> {item.description} </p>
                      <p className="author"> {item.author} </p>
                    </div>
                  </div>
                </div>
              ))
            )}
        </Slider>
      </div>
    </StyledWrapper>
  );
};

export default Testimonialtech500;
